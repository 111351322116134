<template lang="pug">
    div(class="sz-WidgetDistributionBarChart")
        div(class="sz-WidgetDistributionBarChart-frame")
            div(
                v-if="graphData"
                class="sz-WidgetDistributionBarChart-graph")
                ChartBarDistribution(
                  :graphData="graphData"
                  :riskScoreType="riskScoreType",
                  :toggleGraph="toggleGraph",
                  ref="barChart")

</template>

<script>
    import ChartBarDistribution from './ChartBarDistribution'
    import constants from 'helpers/constants'
    import { cloneDeep } from 'lodash'
    import { mapGetters } from 'vuex'
    import colors from '../../design/_colors.scss'
    import { setBarColor } from 'helpers/widgetGraph'
    import { formatRiskScore } from 'helpers/utilities'

    export default {
        name: 'WidgetDistributionBarChart',

        components: {
            ChartBarDistribution,
        },

        props: {
            title: {
                type: String,
                required: true,
            },
            widgetData: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
            distributionType: {
                type: String,
                required: true,
            },
            riskScoreType: {
                type: String,
                required: true,
            },

            toggleGraph: {
                type: Boolean,
                required: false,
            },
        },

        data() {
            return {
                errorStatus: false,
            }
        },

        computed: {
            ...mapGetters([
                'allAssessmentTags',
            ]),

            graphData () {
                let graphData = {}
                if (!this.loading) {
                    graphData.widgetOrder = cloneDeep(this.widgetData.widgetOrder)
                    graphData.type = cloneDeep(this.distributionType)
                    graphData.data = cloneDeep(this.widgetData.data)
                    this.formatData(graphData, this.riskScoreType)

                    graphData.data.sort((prev, current) => {
                      return current.risk - prev.risk
                    })
                } else {
                    graphData = null
                }
                return graphData
            },

            error: {
                set(bool){
                    this.errorStatus = bool
                },

                get() {
                    return this.errorStatus
                },
            },
        },

        errorCaptured: function(err, component, details) {
            this.$parent.error = true
        },

        methods: {
           formatData (graphData, riskScoreType) {
                Object.values(graphData.data).forEach(point => {
                    switch (this.distributionType) {
                        case constants.DISTRIBUTION_CHART_TYPE_API_KEYS.RISK_TYPE_DIST_CHART:
                            this.formatRiskData(point, riskScoreType)
                            break
                        case constants.DISTRIBUTION_CHART_TYPE_API_KEYS.TAG_DIST_CHART:
                            point.tag = this.allAssessmentTags.find(tag => tag.id === point.tag).name
                            this.formatTagJobData(point, point.tag, riskScoreType)
                            break
                        case constants.DISTRIBUTION_CHART_TYPE_API_KEYS.JOB_TYPE_DIST_CHART:
                            this.formatTagJobData(point, point.job, riskScoreType)
                            break
                        default:
                            break
                    }
               })
                return graphData
            },

            formatTagJobData(point, pointTitle, riskScoreType) {
                if (point !== undefined){
                    if (riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY) {
                        point.tooltipTitle = pointTitle
                        point.tooltipBody =
                        `<div class="anychart-tooltip-tagjobTooltip"></div>
                        <div class="anychart-tooltip-totalCaptures">Total Captures<br><b>${point.assessment_count}</b></div>
                        <div class="label">Risk Severity<br></div>
                        <div class="anychart-tooltip-riskScore">${formatRiskScore(point.risk, riskScoreType)}<div>`
                        //y-axis workaround
                        //risk scores of 0 have been converted to 0.001 in order to be plotted
                        //will still appear green and display a risk score of 0.000 in the tooltip
                        point.color = setBarColor(point.risk)
                        point.risk = (point.risk === 0) ? 0.001 : point.risk
                        point.name = pointTitle
                    } else {
                        point.tooltipTitle = pointTitle
                        point.tooltipBody =
                        `<div class="anychart-tooltip-tagjobTooltip"></div>
                        <div class="anychart-tooltip-totalCaptures">Total Captures<br><b>${point.assessment_count}</b></div>
                        <div class="label">Total Risks<br></div>
                        <div class="anychart-tooltip-riskScore">${Math.trunc(point.risk)}<div>`
                        point.risk = Math.trunc(point.risk)
                        point.name = pointTitle
                    }
                }

                //error check
                if (pointTitle === undefined ||
                    point.risk === undefined ||
                    point.assessment_count === undefined ||
                    point.tooltipBody === undefined){
                    throw new Error('required value to create chart is undefined')
                }
            },

            formatRiskData(point, riskScoreType) {
                if (point !== undefined) {
                    if (riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY) {
                        point.tooltipTitle = this.formatRiskType(this.$t(`riskTypes.${point.name}`))
                        point.tooltipBody =
                        `<div class="anychart-tooltip-totalCaptures">Total Captures<br><b>${point.assessment_count}</b></div>
                        <div class="label">Risk Severity<br></div><div class="anychart-tooltip-riskScore">${formatRiskScore(point.risk, riskScoreType)}<div>`
                        point.name = this.$t(`distributionGraphLabels.riskType.${point.name}`)
                        //y-axis workaround
                        //risk scores of 0 have been converted to 0.001 in order to be plotted
                        //will still appear green and display a risk score of 0.000 in the tooltip
                        point.color = setBarColor(point.risk)
                        point.risk = (point.risk === 0) ? 0.001 : point.risk
                    } else {
                        point.tooltipTitle = this.formatRiskType(this.$t(`riskTypes.${point.name}`))
                        point.tooltipBody =
                        `<div class="anychart-tooltip-totalCaptures">Total Captures<br><b>${point.assessment_count}</b></div>
                        <div class="label">Total Risks<br></div><div class="anychart-tooltip-riskScore">${Math.trunc(point.risk)}<div>`
                        point.name = this.$t(`distributionGraphLabels.riskType.${point.name}`)
                        point.risk = Math.trunc(point.risk)
                    }
                }

                //error check
                if (point.tooltipTitle === undefined ||
                    point.risk === undefined ||
                    point.tooltipBody === undefined ||
                    point.name === undefined){
                    throw new Error('undefined value required to create chart')
                }
            },

            formatRiskType(name) {
                if(name === this.$t(`riskTypes.${constants.ERGO_RISK_TYPES.LEFT_ELBOW_PRON}`) ||
                name === this.$t(`riskTypes.${constants.ERGO_RISK_TYPES.RIGHT_ELBOW_PRON}`)) {
                    return name.replace("/", "/&#8203")
                }
                return name
            },

            exportChart() {
                if (this.$refs.barChart) {
                    return this.$refs.barChart.exportChart()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-WidgetDistributionBarChart {
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 100%;

        &-frame {
            width: 100%;
            position: relative;
            max-height: 15.62rem;
            max-width: 32rem;
        }
        &-graph {
            position: relative;
            max-height: 15.625rem;
            max-width: 32rem;
            padding-top: 0.5rem;
        }
    }
</style>
