<template lang="pug">
    div(class="sz-SenzDashboard")
        div(
            id="sz-SenzDashboard-sidebar",
            class="sz-SenzDashboard-sidebar",
            :class="{'sidebar-open': sliderOpen}")
            SideBarHome(:open="sliderOpen")
        div(
            id="sz-SenzDashboard-slider",
            class="sz-SenzDashboard-slider",
            :class="{'slider-open': sliderOpen}")
            div(
                @click="toggleSideBar",
                class="sz-SenzDashboard-slider-button",)
                SvgIcon(
                    icon="arrow",
                    width="15",
                    height="15",
                    :class="{'open': sliderOpen}",
                    class="sz-SenzDashboard-slider-button-arrow")
        div(class="sz-SenzDashboard-main")
            transition(name="fade")
                div(
                    v-if="widgetSettingsLoaded",
                    class="sz-SenzDashboard-widgets")
                    draggable(:list="mutableWidgetsSettings",
                            class="sz-SenzDashboard-widgets",
                            :scroll-sensitivity="200",
                            ghost-class="sz-draggablePreview",
                            :disabled="disableDraggable"
                            @change="updateOrder",
                            :force-fallback="true")
                        Widget(
                            v-for="widget in mutableWidgetsSettings",
                            :key="widget.id",
                            @isDraggableDisabled="setDraggableState"
                            :widgetSettings="widget",
                            class="sz-SenzDashboard-widgets-widget")
</template>

<script>
    import { mapGetters } from 'vuex'
    import Widget from '../HomeDashboard/Widget'
    import SideBarHome from '../HomeDashboard/SideBarHome'
    import SvgIcon from 'components/Shared/SvgIcon'
    import { cloneDeep } from 'lodash'
    import EventBus from 'src/eventBus'
    import draggable from 'vuedraggable'

    export default {
        name: 'SenzDashboard',

        components: {
            Widget,
            SideBarHome,
            SvgIcon,
            draggable,
        },

        data() {
            return {
                mutableWidgetsSettings: [],
                disableDraggable: false,
            }
        },

        computed: {
            ...mapGetters([
                'widgetsSettings',
                'nextWidgetOrder',
                'widgetSettingsLoaded',
                'jobs',
                'allCompanyTags',
                'availableUsers',
                'sliderOpen',
            ]),

            widgetIDList() {
                return this.mutableWidgetsSettings.map(widgetSettings => widgetSettings.id)
            },
        },

        watch: {
            widgetsSettings() {
                this.mutableWidgetsSettings = cloneDeep(this.widgetsSettings)
            },
        },

        async mounted () {
            if (!this.jobs || this.jobs.length === 0) {
                this.$store.dispatch('updateJobTypes')
            }
            if (!this.allCompanyTags || this.allCompanyTags.length === 0) {
                this.$store.dispatch('getAllCompanyTags')
            }
            if (!this.availableUsers || this.availableUsers.length === 0) {
                this.$store.dispatch('updateAvailableUsers')
            }
            if (!this.allAssessmentTags || this.allAssessmentTags.length === 0) {
                this.$store.dispatch('getAllAssessmentTags')
            }
      
            await this.$store.dispatch('updateWidgetsSettings')
            this.mutableWidgetsSettings = cloneDeep(this.widgetsSettings)
    
            EventBus.$on('UPDATE_MUTABLE_WIDGET_SETTINGS', (WidgetInfo) => {
                this.updateWidgetsSettings(WidgetInfo)
            })

            EventBus.$on('ADD_MUTABLE_WIDGET_SETTINGS', (WidgetInfo) => {
                this.addToWidgetsSettings(WidgetInfo)
            })

            EventBus.$on('DELETE_MUTABLE_WIDGET_SETTINGS', (WidgetInfo) => {
                this.deleteFromWidgetsSettings(WidgetInfo)
            })
        },

         beforeDestroy () {
            EventBus.$off('UPDATE_MUTABLE_WIDGET_SETTINGS')
            EventBus.$off('ADD_MUTABLE_WIDGET_SETTINGS')
            EventBus.$off('DELETE_MUTABLE_WIDGET_SETTINGS')
        },

        methods: {
            toggleSideBar() {
                if (this.sliderOpen) {
                    return this.$store.dispatch('updateSlider', false)
                }
                return this.$store.dispatch('updateSlider', true)
            },

            updateWidgetsSettings(WidgetInfo) {
                let stateIndex = this.widgetsSettings.findIndex(widgetSettings => widgetSettings.id === WidgetInfo.widgetId)
                let mutableIndex = this.mutableWidgetsSettings.findIndex(widgetSettings => widgetSettings.id === WidgetInfo.widgetId)
                this.$set(this.mutableWidgetsSettings, mutableIndex, this.widgetsSettings[stateIndex])
            },

            addToWidgetsSettings(WidgetInfo) {
                this.mutableWidgetsSettings.push(this.widgetsSettings.find(widgetSettings => WidgetInfo.widgetId === widgetSettings.id))
            },

            deleteFromWidgetsSettings(WidgetInfo) {
                this.mutableWidgetsSettings = this.mutableWidgetsSettings.filter(widgetSettings => WidgetInfo.widgetId != widgetSettings.id)
            },

            async updateOrder() {
                this.$store.dispatch('updateWidgetOrder', this.widgetIDList)
            },

            setDraggableState(draggable) {
                this.disableDraggable = draggable
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-SenzDashboard {
        position: relative;
        display: flex;
        flex-direction: row;
        height: calc(100vh - 6.5rem);
        overflow-y: hidden;

        &-sidebar {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            height: 98%;
            width: 0;

            &.sidebar-open {
                width: 10rem;
            }
        }

        &-slider {
            width: 0.0625rem;
            margin-left: 0.5rem;
            background-color: darken($color-base-grey, 40%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: margin-left 0.5s;

            &.slider-open {
                margin-left: 10rem;
            }

            &-button {
                width: 1rem;
                height: 1rem;
                border-radius: 0.5rem;
                background-color: $color-lifebooster-light-green;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                z-index: $layer-top-level;
                cursor: pointer;

                &-arrow {
                    transform: rotate(-90deg);
                    padding-right: 0.0625rem;
                    padding-top: 0.25rem;
                    &.open {
                        transform: rotate(90deg);
                        padding-left: 0.125rem;
                        padding-top: 0.25rem;
                    }
                }
            }
        }

        &-main {
            overflow-y: auto;
            @include scrollbar-widget;
            height: 100%;
        }

        &-widgets {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;

            &-widget {
                margin: 0 0.5rem 1rem 0.5rem;
            }
        }
        &-newWidget {
            &-box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 8rem;
                width: 8rem;
                margin: 0 0.5rem 1rem 0.5rem;
                background-color: $color-box-silver;
                border: 1px solid $color-box-outline-dark;
                border-radius: 3px;
                color: $color-table-font-black;
                cursor: pointer;
            }
            &-plus {
                font-size: 3rem;
            }
        }
    }
    .fade {
        &-enter-active,
        &-enter-to,
        &-leave-active {
            transition: opacity 0.2s;
        }
        &-enter,
        &-leave-to {
            opacity: 0;
        }
    }

.sz-draggablePreview {
  opacity: 0.0;
}

</style>
