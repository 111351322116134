<template lang="pug">
    div(class="sz-WidgetDistributionBarChart")
        div(class="sz-WidgetDistributionBarChart-frame")
            div(
                v-if="graphData"
                class="sz-WidgetDistributionBarChart-graph")
                ChartBarDistribution(
                  :graphData="graphData"
                  :riskScoreType="riskScoreType",
                  :chartType="distributionType",
                  :toggleGraph="toggleGraph",
                  ref="barChart")
</template>

<script>
    import ChartBarDistribution from './ChartBarDistribution'
    import constants from 'helpers/constants'
    import { cloneDeep } from 'lodash'
    import { mapGetters } from 'vuex'
    import colors from '../../design/_colors.scss'
    import { setBarColor } from 'helpers/widgetGraph'
    import { formatRiskScore } from 'helpers/utilities'

    export default {
        name: 'WidgetChangeAnalysisChart',

        components: {
            ChartBarDistribution,
        },

        props: {
            title: {
                type: String,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
            widgetData: {
                type: Object,
                required: true,
            },
            distributionType: {
                type: String,
                required: true,
            },
            riskScoreType: {
                type: String,
                required: true,
            },
            toggleGraph: {
                type: Boolean,
                required: false,
            },
        },

        data() {
            return {
                errorStatus: false,
            }
        },

        computed: {
            ...mapGetters([
                'allAssessmentTags',
            ]),

            graphData () {
                let graphData = {}
                if (!this.loading) {
                    graphData.widgetOrder = cloneDeep(this.widgetData.widgetOrder)
                    graphData.type = cloneDeep(this.distributionType)
                    graphData.data = cloneDeep(this.widgetData.data)
                    this.formatData(graphData, this.riskScoreType)
                } else {
                    graphData = null
                }
                return graphData
            },

            error: {
                set(bool){
                    this.errorStatus = bool
                },

                get() {
                    return this.errorStatus
                },
            },
        },

        errorCaptured: function(err, component, details) {
            this.$parent.error = true
        },

        methods: {
           formatData (graphData, riskScoreType) {
                Object.values(graphData.data).forEach(point => 
                    this.formatTooltipData(point, point.label, riskScoreType))
                    
                return graphData
            },

            formatTooltipData(point, pointTitle, riskScoreType) {
               if (point){
                    if (riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY) {
                        point.tooltipTitle = pointTitle
                        point.tooltipBody =
                        `<div class="anychart-tooltip-tagjobTooltip"></div>
                        <div class="anychart-tooltip-totalCaptures">Total Captures<br><b>${point.assessment_count}</b></div>
                        <div class="label">Risk Severity<br></div>
                        <div class="anychart-tooltip-riskScore">${formatRiskScore(point.risk, riskScoreType)}<div>`
                        //y-axis workaround
                        //risk scores of 0 have been converted to 0.001 in order to be plotted
                        //will still appear green and display a risk score of 0.000 in the tooltip
                        point.color = setBarColor(point.risk)
                        point.risk = (point.risk === 0) ? 0.001 : point.risk
                        point.label = pointTitle
                    } else {
                        point.tooltipTitle = pointTitle
                        point.tooltipBody =
                        `<div class="anychart-tooltip-tagjobTooltip"></div>
                        <div class="anychart-tooltip-totalCaptures">Total Captures<br><b>${point.assessment_count}</b></div>
                        <div class="label">Total Risks<br></div>
                        <div class="anychart-tooltip-riskScore">${Math.trunc(point.risk)}<div>`
                        point.risk = Math.trunc(point.risk)
                        point.label = pointTitle
                    }
                }

                //error check
                if (point.tooltipTitle === undefined ||
                    point.risk === undefined ||
                    point.tooltipBody === undefined ||
                    point.label === undefined){
                    throw new Error('undefined value required to create chart')
                }
            },

            exportChart() {
                if(this.$refs.barChart) {
                    return this.$refs.barChart.exportChart()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-WidgetDistributionBarChart {
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 100%;

        &-frame {
            width: 100%;
            position: relative;
            max-height: 15.62rem;
            max-width: 32rem;
        }
        &-graph {
            position: relative;
            max-height: 15.625rem;
            max-width: 32rem;
            padding-top: 0.5rem;
        }
    }
</style>
