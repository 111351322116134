<template lang="pug">
    div(class="sz-assessmentSummary")
        div(class="sz-assessmentSummary-summaryHeader")
            div(v-for="summaryLabel in summaryAssessmentLabels") {{summaryLabel.name}}
                div(class="sz-assessmentSummary-summaryHeader-text") {{summaryAssessmentData[summaryLabel.id]}}
        div(class="sz-assessmentSummary-summaryHeader-title") {{$t(`assessmentSummary.summaryUserList`)}}
        div(class="sz-outerBorder")
            div(class="sz-summaryUserList" v-for="fullName in userNameList") 
                div(class="sz-summaryUserList-text") {{fullName}}
</template>

<script>
    import { cloneDeep } from 'lodash'
    import constants from 'helpers/constants'

    export default {
        name: "WidgetAssessmentSummary",
        
        props: {
            title: {
                type: String,
                required: true,
            },
            widgetData: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
        },

        data () {
            return {
                 currentSort: 'lastName',
            }
        },

        computed: {

            summaryAssessmentLabels() {
                return Object.values(constants.SUMMARY_ASSESSMENT_LABELS).map(summaryAssessmentLabel => {
                    return {
                        id: summaryAssessmentLabel,
                        name:  this.$t(`assessmentSummary.assessmentSummaryLabels.${summaryAssessmentLabel}`),
                    }
                })
            },

            summaryAssessmentData() {
                let summaryStatsData = {}
                 if (!this.loading) {
                    summaryStatsData = cloneDeep(this.widgetData.data[0].aggregatedStats)

                    const DATE_END_OFFSET = 10 //past the first 10 characters date information is not useful
                    summaryStatsData.latestAssessment = summaryStatsData.latestAssessment ? 
                        summaryStatsData.latestAssessment.substring(0, DATE_END_OFFSET) : 'N/A'
                    summaryStatsData.oldestAssessment = summaryStatsData.oldestAssessment ? 
                        summaryStatsData.oldestAssessment.substring(0, DATE_END_OFFSET) : 'N/A'
                    summaryStatsData.totalAssessmentHours = summaryStatsData.totalAssessmentHours ? 
                        summaryStatsData.totalAssessmentHours.toFixed(0) : 'N/A' //round to a whole number
                }

                return summaryStatsData
            },

            userNameList() {
                let userData = {}
                let userNameList = []

                if (!this.loading) {
                    userData = cloneDeep(this.widgetData.data[0].userList)

                    if(this.summaryAssessmentData.anonymousPopulation == 1) {
                        userNameList.push(this.$t(`assessmentSummary.anonUser`))
                    }
                    else if(this.summaryAssessmentData.anonymousPopulation > 1) {
                         userNameList.push(this.$t(`assessmentSummary.anonUsers`))
                    }

                    userData.sort((a,b) => {
                        let direction = 1 //sort ascending order
                        if (a[this.currentSort] < b[this.currentSort]) return -1 * direction
                        if (a[this.currentSort] > b[this.currentSort]) return direction
                        return 0
                    }).map((user) => {
                        if(user.job !== this.$t(`assessmentSummary.anonymousLabel`)) {
                            //If job is anonymous then this implies the user is also, which we want to avoid displaying
                            if(user.firstName || user.lastName) {
                                user.lastName = user.lastName || ''
                                user.firstName = user.firstName || ''

                                let fullName = `${user.firstName} ${user.lastName}`
                                userNameList.push(fullName)
                            }

                        }
                    })
                }
                return userNameList
            },
            
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-assessmentSummary {
        width: 100%;
        margin-left: 2px;
        display: flex;
        align-items: center;
        flex-direction: column;

        &-summaryHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 90%;
            margin-top: 5px;
            height: 4rem;
            font-size: 16px;

            &-title {
                @extend %font-topbar-heading;
                color: $color-white;
                font-size: 14px;
                justify-content: left;
                align-items: flex-start;
                margin: 0px 0px 3px 0px;
            }

            &-text {
                display: flex;
                margin-top: 0.185rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                @extend %font-heading;
                color: lighten($color-black, 50%);
            }
            
        }
    }

    .sz-outerBorder {
        background-color: $color-body-bg;
        height: 8.5rem;
        width: 27rem;
        overflow: auto;
        @include scrollbar-widget;
        padding: 3px 0px 0px 5px;
        border-radius: 5px 5px 5px 5px;
        border-width: thin;
        border-style: solid;
        border-color: $color-lifebooster-medium-green;
    }

    .sz-summaryUserList {
        display: inline;
        flex-direction: row;
        float: left;

        &-text {
            display: flex;
            align-items: center;
            padding: 0.2rem 0.3rem;
            margin: 0.150rem;
            border-radius: 3px;
            background-color: $color-UserInfo-background;
            cursor: default;
            color: $color-white;
            @extend %font-content;
            font-size: 13px;
        }          
    }
</style>
