import constants from 'helpers/constants'
import colors from "design/_colors.scss"

export const generalSettings = {
    chart: {
        width: '100%',
        height: '100%',
        tooltip: {
            enabled: true,
            html: true,
            separator: false,
        },
        background: {
            fill: constants.COLORS.WIDGET.GRAPH,
        },
        yAxis: {
            axisColor: constants.COLORS.WIDGET.AXIS_COLOR,
            labels: {
                enabled: true,
                fontFamily:'Source Sans Pro',
                fontColor: constants.COLORS.WIDGET.YAXIS_COLOR,
                fontSize: '10px',
                fontWeight: '600',
            },
            title: {
                fontSize: '11px',
                fontWeight: '600',
                fontFamily: 'Source Sans Pro',
                fontColor: constants.COLORS.WIDGET.TITLE_LABEL_COLOR,
            },
            ticks: {
                enabled: false,
            },
        },
        xAxis: {
            axisColor: constants.COLORS.WIDGET.AXIS_COLOR,
            labels: {
                enabled: true,
                fontSize: '9px',
                fontFamily:'Source Sans Pro',
                fontColor: constants.COLORS.WIDGET.XAXIS_COLOR,
                fontColorGreen: constants.COLORS.WIDGET.XAXIS_COLOR_GREEN,
                fontColorRed: constants.COLORS.WIDGET.XAXIS_COLOR_RED,
                textOverflow: '...',
                wrap: 'break-all',
                hAlign: 'end',
            },
            ticks: {
                enabled: false,
            },
        },
        title: {
            labels: {
                enabled: true,
                fontSize: '9px',
                fontFamily: 'Source Sans Pro',
                fontColor: constants.COLORS.WIDGET.XAXIS_COLOR,
                fontColorGreen: constants.COLORS.WIDGET.XAXIS_COLOR_GREEN,
                fontColorRed: constants.COLORS.WIDGET.XAXIS_COLOR_RED,
                textOverflow: '...',
                wrap: 'break-all',
                hAlign: 'end',
            },
            ticks: {
                enabled: false,
            },
        },
        grid: {
            enabled: true,
            color: constants.COLORS.WIDGET.GRID_COLOR,
        },
        listen: {
            type: 'hover',
        },
        interactivity: {
            none: 'none',
        },
        markers: {
            colors: {
                red: colors['color-risk-red'],
                orange: colors['color-risk-orange'],
                yellow: colors['color-risk-yellow'],
                green: colors['color-risk-green'],
              },
        },
    },

    barChart: {
        type: 'column',
        barWidth: 6,
        xAxis: {
            labels: {
                rotation: -40,
                overlap: 'allow-overlap',
            },
        },
        series: {
            barColorHover: constants.COLORS.WIDGET.BAR_COLOR_HOVERED,
            barColorNormal: constants.COLORS.WIDGET.BAR_COLOR_NORMAL,
        },
    },

    trendChart: {
        type: 'line',
        labels: {
            format: `{%tickValue}{dateTimeFormat:MMM dd}`,
            caps: 'small-caps',
        },
        series: {
            color: constants.COLORS.WIDGET.TREND_LINE_COLOR,
            thickness: 1,
        },
        markers: {
            enabled: true,
            size: '4',
            color: constants.COLORS.WIDGET.TREND_MARKER_COLOR,

        },
        tooltip: {
            anchor: 'left-bottom',
            titleFormat: '{%x}{dateTimeFormat:MMM dd y}',
            offsetY: '-80',
            pointMode: 'point',
        },
    },
}

export function setBarColor(risk) {
  if (risk >= 0.1) {
    return generalSettings.chart.markers.colors.red
  }
  else if (risk < 0.1 && risk >= 0.01) {
    return generalSettings.chart.markers.colors.orange
  }
  else if (risk < 0.01 && risk >= 0.001) {
    return generalSettings.chart.markers.colors.yellow
  }
  else {
    return generalSettings.chart.markers.colors.green
  }
}

export function setMarkerColor(risk) {
  var color
  if (risk >= 0.1) {
    color = generalSettings.chart.markers.colors.red
  }
  else if (risk < 0.1 && risk >= 0.01) {
    color = generalSettings.chart.markers.colors.orange
  }
  else if (risk < 0.01 && risk >= 0.001) {
    color = generalSettings.chart.markers.colors.yellow
  }
  else {
    color = generalSettings.chart.markers.colors.green
  }
  return {
    enabled: generalSettings.trendChart.markers.enabled,
    fill: color,
    stroke: color,
    size: generalSettings.trendChart.markers.size,
  }
}

export function replaceLabels() {
    if (this.value === 0.0032) {
        return "Low"
    }
    else if (this.value === 0.032) {
        return "Med"
    }
    else if (this.value === 0.320) {
        return "High"
    }
    else {
        return
    }
}

export function replaceLinearLabels() {
    if (this.value === 0.05) {
        return "Med"
    }
    else if (this.value === 0.550) {
        return "High"
    }
    else {
        return
    }
}

export function generalStyling(chart, titleColor = generalSettings.chart.title.labels.fontColor) {
    chart.background().fill(generalSettings.chart.background.fill)

    //labels
    var xAxisLabels = chart.xAxis().labels()
    xAxisLabels .enabled(generalSettings.chart.xAxis.labels.enabled)
    xAxisLabels.fontSize(generalSettings.chart.xAxis.labels.fontSize)
    xAxisLabels.minFontSize(generalSettings.chart.xAxis.labels.fontSize)
    xAxisLabels.fontFamily(generalSettings.chart.xAxis.labels.fontFamily)
    xAxisLabels.fontColor(generalSettings.chart.xAxis.labels.fontColor)
    xAxisLabels.textOverflow(generalSettings.chart.xAxis.labels.textOverflow)
    xAxisLabels.wordWrap(generalSettings.chart.xAxis.labels.wrap)
    xAxisLabels.hAlign(generalSettings.chart.xAxis.labels.hAlign)
    chart.xAxis().labels().format(function() {
        return this.value.toUpperCase()
    })

    var yAxisLabels = chart.yAxis().labels()
    yAxisLabels.fontSize(generalSettings.chart.yAxis.labels.fontSize)
    yAxisLabels.fontColor(generalSettings.chart.yAxis.labels.fontColor)
    yAxisLabels.fontWeight(generalSettings.chart.yAxis.labels.fontWeight)
    yAxisLabels.fontFamily(generalSettings.chart.yAxis.labels.fontFamily)
    var title = chart.yAxis().title()
    title.fontSize("10px")
    title.fontWeight("600")
    title.fontFamily("Source Sans Pro")
    title.fontColor("rgba(255, 255, 255, 0.75)")

    chart.yAxis().ticks().enabled(generalSettings.chart.yAxis.ticks.enabled)
    chart.yAxis().stroke(generalSettings.chart.yAxis.axisColor)
    chart.xAxis().ticks().enabled(generalSettings.chart.xAxis.ticks.enabled)
    chart.xAxis().stroke(generalSettings.chart.xAxis.axisColor)

    var tooltip = chart.tooltip()
    tooltip.useHtml(generalSettings.chart.tooltip.enabled)
    tooltip.separator(generalSettings.chart.tooltip.separator)

    chart.interactivity().selectionMode(generalSettings.chart.interactivity.none)

    var title = chart.title()
    title.fontColor(titleColor)
    title.fontSize("33px")
    title.fontWeight(generalSettings.chart.title.labels.fontWeight)
    title.fontFamily(generalSettings.chart.title.labels.fontFamily)

}
