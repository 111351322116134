<template lang="pug">
    div(class="sz-SideBarHome")
        div(class="sz-SideBarHome-title") {{ $t(`homePage.workers`) }}
        div(class="sz-SideBarHome-search")
            input(
                type="text",
                v-model="search",
                class="sz-SideBarHome-search-input")
            SvgIcon(
                icon="search",
                width="0.5rem",
                height="0.5rem",
                class="sz-SideBarHome-search-icon")
        div(
            :class="{'sz-SideBarHome-list-open': open, }",
            class="sz-SideBarHome-list")
            div(
                v-if="hasUsers",
                class="sz-SideBarHome-list-empty") {{ $t(`homePage.emptyList`) }}
            transition(name="fade")
                div(
                    v-if="users && users.length > 0 && !showLoadingState"
                    class="sz-SideBarHome-table")
                    div(class="sz-SideBarHome-table-headers")
                        div(
                            v-for="header in headers",
                            :key="header.name",
                            @click="setCurrentSort(header.name)",
                            class="sz-SideBarHome-table-headers-header",
                            :class="'sz-SideBarHome-table-headers-header-' + header.name")
                            div(class="sz-SideBarHome-table-headers-header-content")
                                div(class="sz-SideBarHome-table-headers-header-title") {{ header.title }}
                                SvgIcon(
                                    icon="arrow",
                                    width="10",
                                    height="10",
                                    class="sz-SideBarHome-table-headers-header-arrow",
                                    :class="['sz-SideBarHome-table-headers-header-arrow-' + currentSortDirection,\
                                        {'sz-SideBarHome-table-headers-header-arrow-show': header.name === currentSort}]")
                    div(
                        v-for="user in sortedAndFilteredUserList",
                        @click="openIndividualSummary(user.userID)",
                        class="sz-SideBarHome-table-row"
                        :class="{'sz-SideBarHome-table-row-clickable': user.userID}")
                        div(
                            v-for="header in headers",
                            class="sz-SideBarHome-table-row-item",
                            :class="'sz-SideBarHome-table-row-item-' + header.name")
                            div(
                                v-truncated-tooltip="",
                                :id="`sz-SideBarHome-table-row-item-data-${header.name}`"
                                class="sz-SideBarHome-table-row-item-data",
                                :class="'sz-SideBarHome-table-row-item-data-' + header.name") {{ user[header.name] }}
            div(
                v-if="showLoadingState",
                class="sz-SideBarHome-loader")
                LoadingSpinner
</template>

<script>
    import LoadingSpinner from '../Shared/LoadingSpinner'
    import SvgIcon from '../Shared/SvgIcon'
    import { mapGetters } from 'vuex'
    import { cloneDeep, get } from 'lodash'
    import { TruncatedTooltip } from 'directives/truncatedTooltip'
    import { splitFirstNameLastName } from '@/helpers/utilities'

    export default {
        name: "SideBarHome",

        components: {
            LoadingSpinner,
            SvgIcon,
        },

        directives: {
            TruncatedTooltip,
        },

        props: {
            open: {
                required: true,
                type: Boolean,
            },
        },

        data () {
            return {
                search: '',
                currentSort: 'firstName',
                currentSortDirection: 'up',
                headers: [
                    {
                        name: 'firstName',
                        title: this.$t(`homePage.headers.fname`),
                    },
                    {
                        name: 'lastName',
                        title: this.$t(`homePage.headers.lname`),
                    },
                ],
            }
        },

        computed: {
            ...mapGetters([
                'availableUsers',
                'loadingUsers',
            ]),

            showLoadingState () {
                return this.open && this.loadingUsers
            },

            users () {
                return this.availableUsers
            },

            hasUsers () {
                return (this.users && this.users.length === 0) && !this.showLoadingState
            },

            sortedAndFilteredUserList () {
                let users = cloneDeep(this.users)
                let sortAndFilter = []

                if (users.length > 0) {
                    sortAndFilter = users
                    .map((item) => {
                        if (!item.firstName && !item.lastName) {
                            item.firstName = this.$t(`homePage.worker`)
                        }
                        return item
                    }).filter(item => {
                        let fullName = splitFirstNameLastName(this.search.toLowerCase())
                        if (item.firstName || item.lastName) {
                            return (get(item, 'firstName', '').trim().toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1)
                            || (get(item, 'lastName', '').trim().toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1)
                            || ((get(item, 'firstName', '').trim().toLowerCase().indexOf(fullName.firstName) > -1)
                            && (get(item, 'lastName', '').trim().toLowerCase().indexOf(fullName.lastName) > -1))
                        }
                        return -1
                    }).sort((a,b) => {
                        let direction = 1
                        if (this.currentSortDirection === 'down') direction = -1
                        if (get(a, this.currentSort, '').toLowerCase() < get(b, this.currentSort, '').toLowerCase()){
                            return -1 * direction
                        }
                        if (get(a, this.currentSort, '').toLowerCase() > get(b, this.currentSort, '').toLowerCase()) {
                            return direction
                        }
                        return 0
                    })
                }

                return sortAndFilter
            },
        },

        methods: {
            setCurrentSort (header) {
                if (header === this.currentSort) {
                    this.currentSortDirection = this.currentSortDirection === 'up' ? 'down' : 'up'
                }
                this.currentSort = header
            },

            openIndividualSummary (workerId) {
                if (workerId !== undefined) {
                    this.$router.push({name: 'individual-summary', params: {workerId: workerId}})
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-SideBarHome {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        overflow-y: hidden;
        overflow-x: hidden;

        &-loader {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            background-color: lighten($color-body-bg, 10%);
            opacity: 0.5;
            filter: blur(1px);
            height: 100%;
            width: 100%;
            margin-left: -0.3rem;
            padding-top: 6rem;
        }

        &-title {
            display: flex;
            flex-direction: row;
            padding: 0.25rem 0.5rem;
            cursor: default;
            height: 1.5rem;
            color: $color-white;
            @extend %font-topbar-heading-unselectable;
        }

        &-list {
            display: flex;
            flex-grow: 1;
            margin: 0.125rem;

            &-open {
                overflow-y: scroll;
                @include scrollbar-widget;
            }

            &-empty {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: inherit;
                @extend %font-topbar-heading-unselectable;
                color: darken($color-white, 60%);
                font-size: 14px;
                overflow: hidden;
            }
        }

        &-search {
            margin: 0 0.125rem;
            display: flex;
            position: relative;
            width: 90%;

            &-input {
                outline: none;
                background-color: inherit;
                border: 1px solid #AFAFAF;
                box-sizing: border-box;
                border-radius: 20px;
                width: 100%;
                height: 1.75rem;
                padding-left: 1rem;
                padding-right: 1.5rem;
                color: $color-white;
                @extend %font-content;
            }

            &-icon {
                position: absolute;
                top: 0;
                right: 0;
                height: 1.8rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top: 0.125rem;
                margin-right: 0.75rem;
            }
        }

        &-table {
            font-size: 12px;
            width: 100%;
            display: flex;
            flex-direction: column;

            &-headers {
                width: 100%;
                height: 2.5rem;
                display: flex;
                flex-direction: row;

                &-header {
                    font-weight: normal;
                    color: $color-box-silver;
                    width: calc(100vw / 2);
                    height: 100%;

                    &-content {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        cursor: pointer;
                    }

                    &-title {
                        @extend %font-topbar-heading-unselectable;
                        padding-right: 0.3rem;
                    }

                    &-arrow {
                        visibility: hidden;
                        padding-top: 0.25rem;

                        &-show {
                            visibility: visible;
                        }

                        &-down {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            &-row {
                width: 100%;
                height: 2.12rem;
                display: flex;
                flex-direction: row;
                align-items: center;

                &-item {
                    display: flex;
                    width: 50%;

                    &-data {
                        text-align: left;
                        height: 100%;
                        color: darken($color-white, 40%);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &-clickable {
                    &:hover {
                        background-color: lighten($color-body-bg, 8%);
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>
