<template lang="pug">
    div(
        class= "ChartTrendLine"
        :id="graphData.widgetOrder")
</template>

<script>
    import Anychart from 'anychart'
    import { generalSettings, generalStyling, replaceLabels, replaceLinearLabels } from 'helpers/widgetGraph'
    import constants from "helpers/constants"

    export default {
        name: 'ChartTrendLine',

        props: {
            graphData: {
                type: Object,
                required: true,
            },
            riskScoreType: {
                type: String,
                required: true,
            },
            toggleGraph: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                chart: null,
                series: null,
            }
        },

        watch: {
            options: function (options) {
                if (!this.chart && options) {
                    this.init()
                } else {
                    this.chart.dispose()
                    this.chart = null
                    this.init()
                }
            },

            toggleGraph: function () {
                this.chart.dispose()
                this.chart = null
                this.init()
            },
        },

        mounted () {
            if (!this.chart) {
                this.init()
            }
        },

        updated() {
            this.init()
        },

        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose()
                this.chart = null
            }
        },

        methods: {
            init () {
                if (!this.chart) {
                    Anychart.licenseKey(process.env.VUE_APP_ANYCHART_LICENSE)
                    this.chart = Anychart.line()
                    let dataSet = Anychart.data.set(this.graphData.data)
                    let mapping = dataSet.mapAs({x: "day", value: "risk"})
                    this.series = this.chart.line(mapping)
                    this.addLicenseKey()
                    this.addGeneralSettings(this.riskScoreType)
                    this.chart.container(this.$el)
                    this.chart.draw()
                }
            },

            addLicenseKey () {
                let credits = this.chart.credits()
                credits.enabled(false)
            },

            addGeneralSettings (riskScoreType) {
                generalStyling(this.chart)
                this.chart.xAxis().labels().format(generalSettings.trendChart.labels.format)
                this.chart.xAxis().labels().fontVariant(generalSettings.trendChart.labels.caps)

                //line
                this.series.stroke(generalSettings.trendChart.series.color,generalSettings.trendChart.series.thickness)
                var markers = this.series.hovered().markers()
                markers.enabled(generalSettings.trendChart.markers.enabled)
                markers.size(generalSettings.trendChart.markers.size)
                markers.stroke(generalSettings.trendChart.markers.color)
                markers.fill(generalSettings.trendChart.markers.color)

                //tooltip
                var tooltip = this.chart.tooltip()
                tooltip.anchor(generalSettings.trendChart.tooltip.anchor)
                tooltip.positionMode(generalSettings.trendChart.tooltip.pointMode)
                tooltip.offsetY(generalSettings.trendChart.tooltip.offsetY)

                tooltip.titleFormat(generalSettings.trendChart.tooltip.titleFormat)

                if (riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY) {
                    this.chart.yAxis().title(this.$t(`distributionGraphLabels.axisLabels.${constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY}`).toUpperCase())
                    this.toggleGraph ? this.linearGraphSettings() : this.logGraphSettings()

                    tooltip.format(function() {
                         return this.getData('tooltipBody')
                    })
                } else {
                      const grid = this.chart.yGrid()
                      grid.enabled(generalSettings.chart.grid.enabled)
                      grid.stroke(generalSettings.chart.grid.color)

                      this.chart.yAxis().title(this.$t(`distributionGraphLabels.axisLabels.${constants.RISK_SCORE_TYPES_API_KEYS.TOTAL_RISKS}`).toUpperCase())

                      tooltip.format(function() {
                          var riskNum = Math.trunc(this.getData('risk'))
                          return `<div class="label">Total Risks<br></div><div class="anychart-tooltip-riskScore">${riskNum}<div>`
                      })
                }
            },

            exportChart() {
                return this.chart
            },

            linearGraphSettings() {
                this.chart.yAxis().labels().format(replaceLinearLabels)
                this.chart.yScale(Anychart.scales.linear())
                this.chart.yScale().minimum(0.0009)
                this.chart.yScale().maximum(1)
                var ticksArray = [0.05, 0.1, 0.200, 0.550]
                this.chart.yScale().ticks().set(ticksArray)

                const med = this.chart.lineMarker(1)
                med.axis(this.chart.yAxis())
                med.value(0.01)
                med.stroke(generalSettings.chart.grid.color)

                const high = this.chart.lineMarker(2)
                high.axis(this.chart.yAxis())
                high.value(0.1)
                high.stroke(generalSettings.chart.grid.color)

                const ceil = this.chart.lineMarker(3)
                ceil.axis(this.chart.yAxis())
                ceil.value(1)
                ceil.stroke(generalSettings.chart.grid.color)


            },

            logGraphSettings() {
                this.chart.yAxis().labels().format(replaceLabels)
                this.chart.yScale(Anychart.scales.log())
                this.chart.yScale().minimum(0.0009)
                this.chart.yScale().maximum(1)
                var ticksArray = [0.001, 0.0032, 0.01, 0.032, 0.1, 0.320, 1]
                this.chart.yScale().ticks().set(ticksArray)

                const floor = this.chart.lineMarker(0)
                floor.axis(this.chart.yAxis())
                floor.value(0.001)
                floor.stroke(generalSettings.chart.grid.color)

                const orange = this.chart.lineMarker(1)
                orange.axis(this.chart.yAxis())
                orange.value(0.01)
                orange.stroke(generalSettings.chart.grid.color)

                const red = this.chart.lineMarker(2)
                red.axis(this.chart.yAxis())
                red.value(0.1)
                red.stroke(generalSettings.chart.grid.color)

                const ceil = this.chart.lineMarker(3)
                ceil.axis(this.chart.yAxis())
                ceil.value(1)
                ceil.stroke(generalSettings.chart.grid.color)
            },
        },
    }
</script>

<style lang="scss">
@import '~design';
.ChartTrendLine {
    height: 14.375rem;
    padding-top: 1rem;
    @include anychart-tooltip;
    .anychart-tooltip {
        width: 6.25rem;
        margin-left: 1rem;
    }
}

</style>
