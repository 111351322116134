<template lang="pug">
    div(
        v-click-away="close",
        class="sz-DropdownEditWidget")
        ul(class="sz-DropdownEditWidget-options")
            li(
                @click.stop="openEditModal"
                class="sz-DropdownEditWidget-option") {{ $t(`widgets.editWidget`) }}
            li(class="sz-DropdownEditWidget-option",
                @click.stop="deleteWidget") {{ $t(`widgets.delete`) }}
            li(v-if="!isSummary",
                class="sz-DropdownEditWidget-option",
                @click.stop="downloadCsv") {{ $t(`widgets.downloadCsv`) }}
            li(v-if="isChart",
                class="sz-DropdownEditWidget-option",
                @click.stop="saveImage") {{ $t(`widgets.saveImage`) }}
</template>

<script>
    import constants from 'helpers/constants'
    import modalSettings from 'mixins/modalSettings'
    import { ClickAway } from 'directives/clickAway'
    import { Parser } from 'json2csv'
    import { cloneDeep } from 'lodash'
    import moment from 'moment-timezone'

    export default {
        name: 'DropdownEditWidget',

        directives: {
            ClickAway,
        },

        mixins: [
            modalSettings,
        ],

        props: {
            widgetSettings: {
                type: Object,
                required: true,
            },
            widgetData: {
                type: Object,
                required: true,
            },
            chart: {
                type: Object,
                required: false,
                default: undefined,
            },
        },

        computed: {
            isChart() {
                return this.widgetSettings.type !== constants.WIDGET_TYPES_API_KEYS.LIST
                    && this.widgetSettings.type !== constants.WIDGET_TYPES_API_KEYS.SUMMARY
            },

            isTrend() {
                return this.widgetSettings.type === constants.WIDGET_TYPES_API_KEYS.TREND
            },

            isSummary() {
                return this.widgetSettings.type === constants.WIDGET_TYPES_API_KEYS.SUMMARY
            },
        },

        methods: {
            openEditModal () {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_EDIT_WIDGET,
                    false,
                    {
                        widgetSettings: this.widgetSettings,
                    },
                    false,
                    true)
                )
            },

            deleteWidget () {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_DELETE_WIDGET,
                    false,
                    {
                        widgetId: this.widgetSettings.id,
                    },
                    true,
                    true
                ))
            },

            downloadCsv() {
                if (this.isChart) {
                        this.chart.saveAsCsv("default", { formats: this.formatCsv }, this.widgetSettings.title.toLowerCase())
                } else {
                    let userListData = (cloneDeep(this.widgetData.data)).map(d => {
                      delete d.assessment_count
                      delete d.userID
                      d.risk = this.widgetSettings.riskScoreType == constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY ? ('000' + Math.round(d.risk * 1000)).slice(-3) : Math.round(d.risk) // This will basically take the first 3 digit after the decimal point and turn it into a string of a whole number
                      return d
                    })
                    const json2csvParser = new Parser()
                    const csv = json2csvParser.parse(userListData)
                    let anchor = document.createElement('a')
                    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
                    anchor.target = '_blank'
                    anchor.download = `${this.widgetSettings.title.toLowerCase()}.csv`
                    anchor.click()
                }
            },

            formatCsv(header, val) {
                if (header === 'x' && this.isTrend) {
                    return moment.unix(val/constants.UNIX_MILLISECONDS).utc().format('L')
                }
                else if (header === 'value'){
                    return this.widgetSettings.riskScoreType == constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY ? ('0' + Math.round(val * 1000)).slice(-3) : Math.round(val)
                } 
                else {
                    return val
                }
            },

            saveImage() {
                this.chart.saveAsJpg({
                  "width": 600,
                  "height": 400,
                  "filename": this.widgetSettings.title.toLowerCase(),
                })
            },

            close () {
                this.$emit('closeDropdown')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        margin: 0;
    }

    .sz-DropdownEditWidget {
        @extend %font-heading;
        position: absolute;
        right: 0;
        top: 1.5rem;
        width: 8rem;
        z-index: $layer-dropdown-z-index;
        background-color: $color-dropdown-background;
        font-size: 0.75rem;
        overflow: hidden;

        &-options {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 100%;
            padding: 0.5rem 0;
        }

        &-option {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            padding: 0.35rem 0.75rem;
            cursor: pointer;

            &:hover {
                background-color:$color-lifebooster-medium-green;
            }
        }
    }
</style>
