<template lang="pug">
    div(class="sz-WidgetTrendChart")
        div(class="sz-WidgetTrendChart-frame")
            ChartTrendLine(
                v-if="graphData"
                class="sz-WidgetTrendChart-graph",
                :graphData="graphData",
                :riskScoreType="riskScoreType",
                :toggleGraph="toggleGraph"
                ref="trendChart")

</template>

<script>
    import ChartTrendLine from './ChartTrendLine'
    import constants from "helpers/constants"
    import { cloneDeep } from 'lodash'
    import moment from 'moment-timezone'
    import { generalSettings, generalStyling, setMarkerColor } from 'helpers/widgetGraph'
    import { formatRiskScore } from 'helpers/utilities'

    export default {
        name: "WidgetTrendChart",

        components: {
            ChartTrendLine,
        },

        props: {
            title: {
                type: String,
                required: true,
            },
            widgetData: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
            riskScoreType: {
                type: String,
                required: true,
            },

            toggleGraph: {
                type: Boolean,
                required: false,
            },
        },

        computed: {
            graphData () {
                let graphData = {}
                if (!this.loading) {
                    graphData.data = this.widgetData.data.map(point => {
                      if (this.riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY) {
                          //y-axis workaround
                          //risk scores of 0 have been converted to 0.001 in order to be plotted
                          //will still appear green and display a risk score of 0.000 in the tooltip
                          point.tooltipBody =
                          `<div class="label">Risk Severity<br></div><div class="anychart-tooltip-riskScore">${formatRiskScore(point.risk, this.riskScoreType)}<div>`
                          point.marker = setMarkerColor(point.risk)
                          point.risk = (point.risk === 0) ? 0.001 : point.risk
                      }
                      point.day = point.day * constants.UNIX_MILLISECONDS
                      return point
                    })

                    graphData.data.sort((prev, current) => {
                      return prev.day - current.day
                    })

                    return graphData
                }
                return null
            },
        },

      methods: {
          exportChart() {
            if (this.$refs.trendChart) {
                return this.$refs.trendChart.exportChart()
            }
          },
      },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-WidgetTrendChart {
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 100%;

        &-frame {
            width: 100%;
            position: relative;
            max-height: 14.5rem;
            max-width: 32rem;
        }
         &-graph {
             position: relative;
             max-height: 13.5rem;
             max-width: 32rem;
        }
    }
</style>
