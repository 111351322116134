<template lang="pug">
    div(
        class= "ChartBarDistribution"
        :id="graphData.widgetOrder")

</template>

<script>
    import Anychart from 'anychart'
    import { generalSettings, generalStyling, setBarColor, replaceLabels, replaceLinearLabels } from 'helpers/widgetGraph'
    import constants from 'helpers/constants'

    export default {
        name: 'ChartBarDistribution',

        props: {
            graphData: {
                type: Object,
                required: true,
            },
            riskScoreType: {
                type: String,
                required: true,
            },
            chartType: {
                type: String,
                required: false,
                default: '',
            },
            toggleGraph: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                chart: null,
                series: null,
            }
        },
        watch: {
            options: function (options) {
                if (!this.chart && options) {
                    this.init()
                } else {
                    this.chart.dispose()
                    this.chart = null
                    this.init()
                }
            },

            toggleGraph: function () {
                this.chart.dispose()
                this.chart = null
                this.init()
            },
        },

        mounted () {
            if (!this.chart) {
                this.init()
            }
        },

        updated() {
            this.init()
        },

        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose()
                this.chart = null
            }
        },

        methods: {
            init () {
                if (!this.chart) {
                    Anychart.licenseKey(process.env.VUE_APP_ANYCHART_LICENSE)
                    this.chart = anychart.column()
                    this.addLicenseKey()
                    var dataSet = anychart.data.set(this.graphData.data)
                    if(this.chartType === constants.ANALYSIS_CHART_TYPE_API_KEYS.TAG_ANALYSIS_CHART
                        || this.chartType === constants.ANALYSIS_CHART_TYPE_API_KEYS.TIME_TYPE_ANALYSIS_CHART) {
                        var mapping = dataSet.mapAs({x: 'label', value: 'risk'})
                    }
                    else { 
                        var mapping = dataSet.mapAs({x: 'name', value: 'risk'})
                    }
                    this.series = this.chart.column(mapping)
                    this.addGeneralSettings(this.riskScoreType)
                    this.chart.container(this.$el)
                    this.chart.draw()
                    this.alignLabels()
                }
            },

            addLicenseKey () {
                let credits = this.chart.credits()
                credits.enabled(false)
            },

            alignLabels() {
                //The purpose of this function is to left align labels as AnyChart(8.7.1) currently
                //does not have this functionality. Values used to change the width/height/offset
                //are "magic numbers" which has been figured out after testing with various strings.
                //Risk type charts have specific values to fit the whole string so its not truncated

                var labels = this.chart.xAxis().labels()
                var labelsCount = this.chart.xAxis().labels().getLabelsCount()

                if(this.graphData.type === constants.DISTRIBUTION_CHART_TYPE_API_KEYS.RISK_TYPE_DIST_CHART
                && labelsCount > 0) {
                    switch(true) {
                        case labelsCount > 10:
                            labels.width(420 / labelsCount + '%')
                            labels.height(30 / labelsCount + '%')
                            labels.offsetX((165 / labelsCount * -1 )+ '%')
                            break
                        case labelsCount > 8:
                            labels.width(310 / labelsCount + '%')
                            labels.height(50 / labelsCount + '%')
                            labels.offsetX((112 / labelsCount * -1 )+ '%')
                            break
                        case labelsCount > 5:
                            labels.width(220 / labelsCount + '%')
                            labels.height(50 / labelsCount + '%')
                            labels.offsetX((80 / labelsCount * -1 )+ '%')
                            break
                        case labelsCount > 3:
                            labels.width(150 / labelsCount + '%')
                            labels.height(1)
                            labels.offsetX((60 / labelsCount * -1 )+ '%')
                            break
                        case labelsCount === 3:
                            labels.width(100 / labelsCount + '%')
                            labels.height(1)
                            labels.offsetX((40 / labelsCount * -1 )+ '%')
                            break
                        case labelsCount === 2:
                            labels.width(70 / labelsCount + '%')
                            labels.height(1)
                            labels.offsetX((30 / labelsCount * -1 )+ '%')
                            break
                        case labelsCount === 1:
                            labels.width(30 / labelsCount + '%')
                            labels.height(1)
                            labels.offsetX((12 / labelsCount * -1 )+ '%')
                            break
                        default: break
                    }
                } else {
                    switch(true) {
                        case labelsCount > 6:
                            labels.width(200 / labelsCount + '%')
                            labels.height(100 / labelsCount + '%')
                            labels.offsetX((60 / labelsCount * -1 )+ '%')
                            break
                        case labelsCount > 1:
                            labels.width(110)
                            let height = (((labelsCount * 5) / labelsCount * 1 ) + '%')
                            labels.height(height)
                            let baseOffset = (labelsCount > 3) ? 0 :  5
                            let offset = (((labelsCount - 1) * 10 + baseOffset ) / (labelsCount * -1 )+ '%')
                            labels.offsetX(offset)
                            break
                        case labelsCount === 1:
                            labels.width(115)
                            labels.height(45)
                            labels.offsetX((10 / labelsCount * -1 )+ '%' )
                            break
                        default: break
                    }
                }
            },

            addGeneralSettings (riskScoreType) {
                let titleColor = generalSettings.chart.xAxis.labels.fontColor

                //labels
                this.chart.xAxis().overlapMode(generalSettings.barChart.xAxis.labels.overlap)
                this.chart.xAxis().labels().rotation(generalSettings.barChart.xAxis.labels.rotation)

                //bar colors
                this.chart.pointWidth(generalSettings.barChart.barWidth)
                this.series.hovered().fill(generalSettings.barChart.series.barColorHover)
                this.series.hovered().stroke(generalSettings.barChart.series.barColorHover)

                if (riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY) {

                    if(this.chartType === constants.ANALYSIS_CHART_TYPE_API_KEYS.TAG_ANALYSIS_CHART
                        || this.chartType === constants.ANALYSIS_CHART_TYPE_API_KEYS.TIME_TYPE_ANALYSIS_CHART) {

                        this.chart.title().useHtml(true)
                        
                        var percentDifference = Math.round((((this.graphData.data[1].risk - this.graphData.data[0].risk)/this.graphData.data[0].risk)*100))
                        if(percentDifference > 1) { //risk increased across the two groups
                            titleColor = generalSettings.chart.title.labels.fontColorRed
                            this.chart.title(`<div style=\"font-weight: 450;\">↑</div> ${percentDifference}%`)
                        }
                        else if(percentDifference < -1) { //risk decreased across the two groups
                            titleColor = generalSettings.chart.title.labels.fontColorGreen
                            this.chart.title(`<div style=\"font-weight: 450;\">↓ ${Math.abs(percentDifference)}%`)
                        }
                        else { // rounds down to zero otherwise
                            this.chart.title(`No Change`)
                        }
                        
                        this.chart.title().padding().set(-10, 0, 5, 35)
                    }

                    this.toggleGraph ? this.linearGraphSettings() : this.logGraphSettings()
                    this.chart.yAxis().title(this.$t(`distributionGraphLabels.axisLabels.${constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY}`).toUpperCase())

                    const floor = this.chart.lineMarker(0)
                    floor.axis(this.chart.yAxis())
                    floor.value(0.001)
                    floor.stroke({
                      color: generalSettings.chart.grid.color,
                      thickness: generalSettings.trendChart.markers.size,
                    })

                    const orange = this.chart.lineMarker(1)
                    orange.axis(this.chart.yAxis())
                    orange.value(0.01)
                    orange.stroke(generalSettings.chart.grid.color)

                    const red = this.chart.lineMarker(2)
                    red.axis(this.chart.yAxis())
                    red.value(0.1)
                    red.stroke(generalSettings.chart.grid.color)

                    const ceil = this.chart.lineMarker(3)
                    ceil.axis(this.chart.yAxis())
                    ceil.value(1)
                    ceil.stroke(generalSettings.chart.grid.color)

                    //conditionally set bar colors
                    this.series.normal().fill(function() {
                        return this.getData('color')
                    })
                    this.series.normal().stroke(function() {
                        return this.getData('color')
                    })

                    this.setTooltipBody(this.graphData.tooltipBody)
                } else {
                    const grid = this.chart.yGrid()
                    grid.enabled(generalSettings.chart.grid.enabled)
                    grid.stroke(generalSettings.chart.grid.color)

                    this.chart.yAxis().title(this.$t(`distributionGraphLabels.axisLabels.${constants.RISK_SCORE_TYPES_API_KEYS.TOTAL_RISKS}`).toUpperCase())

                    this.series.normal().fill(generalSettings.barChart.series.barColorNormal)
                    this.series.normal().stroke(generalSettings.barChart.series.barColorNormal)

                    this.setTooltipBody(this.graphData.tooltipBody)
                }

                generalStyling(this.chart, titleColor)
            },

            linearGraphSettings() {
                this.chart.yAxis().labels().format(replaceLinearLabels)
                this.chart.yScale(Anychart.scales.linear())
                this.chart.yScale().minimum(0.0009)
                this.chart.yScale().maximum(1)
                 var ticksArray = [0.05, 0.1, 0.200, 0.550]
                this.chart.yScale().ticks().set(ticksArray)
            },

            logGraphSettings() {
                    this.chart.yAxis().labels().format(replaceLabels)
                    this.chart.yScale(Anychart.scales.log())
                    this.chart.yScale().minimum(0.001)
                    this.chart.yScale().maximum(1)
                    var ticksArray = [0.001, 0.0032, 0.01, 0.032, 0.1, 0.320, 1]
                    this.chart.yScale().ticks().set(ticksArray)
            },

            setTooltipBody(toolBody) {
                var tooltip = this.chart.tooltip()
                tooltip.titleFormat(function() {
                    return this.getData('tooltipTitle').toUpperCase()
                })
                tooltip.format(function() {
                     return this.getData('tooltipBody')
                })
            },

            exportChart() {
                return this.chart
            },
        },

    }
</script>

<style lang="scss">
@import '~design';
.ChartBarDistribution {
    height: 14.375rem;
    margin-left: 0.3125rem;
    @include anychart-tooltip
}
</style>
