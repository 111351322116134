<template lang="pug">
    div(:class="['sz-WidgetUserList', 'sz-WidgetUserList-' + title]")
        div(class="sz-WidgetUserList-table")
            div(class="sz-WidgetUserList-table-headers")
                div(
                    v-for="header of Object.keys(headers)",
                    @click="setCurrentSort(header)",
                    class="sz-WidgetUserList-table-headers-header"
                    :class="['sz-WidgetUserList-table-headers-header-' + header,\
                    {'sz-WidgetUserList-table-headers-header-showColour': header === currentSort}]")
                    div(class="sz-WidgetUserList-table-headers-header-content")
                        div(
                            class="sz-WidgetUserList-table-headers-header-titleContainer"
                            :class="'sz-WidgetUserList-table-headers-header-titleContainer-' + header")
                            div(class="sz-WidgetUserList-table-headers-header-titleContainer-title") {{ headers[header] }}
                        div(class="sz-WidgetUserList-table-headers-header-container")
                            SvgIcon(
                                icon="arrow",
                                width="10",
                                height="10",
                                class="sz-WidgetUserList-table-headers-header-container-arrow",
                                :class="['sz-WidgetUserList-table-headers-header-container-arrow-' + currentSortDirection,\
                                {'sz-WidgetUserList-table-headers-header-container-arrow-show': header === currentSort}]")
            div(class="sz-WidgetUserList-table-body")
                div(class="sz-WidgetUserList-table-body-rows")
                    div(
                        v-for="user in users",
                        @click="openIndividualSummary(user.userID)",
                        class="sz-WidgetUserList-table-body-rows-row",
                        :class="{'sz-WidgetUserList-table-body-rows-row-clickable': user.userID}")
                            div(
                                v-for="header of Object.keys(headers)",
                                class="sz-WidgetUserList-table-body-rows-row-item",
                                :class="'sz-WidgetUserList-table-body-rows-row-item-' + header")
                                div(
                                    v-truncated-tooltip="",
                                    :id="user[header]",
                                    class="sz-WidgetUserList-table-body-rows-row-item-data",
                                    :class="['sz-WidgetUserList-table-body-rows-row-item-data-' + header + setColor(header, user.risk),\
                                    {'sz-WidgetUserList-table-body-rows-row-item-data-showColour': header === currentSort}]") {{ user[header] }}

</template>

<script>
    import SvgIcon from '../Shared/SvgIcon'

    import { TruncatedTooltip } from 'directives/truncatedTooltip'
    import { cloneDeep } from 'lodash'
    import constants from 'helpers/constants'
    import { formatRiskScore } from 'helpers/utilities'

    export default {
        name: "WidgetUserList",

        components: {
            SvgIcon,
        },

        directives: {
            TruncatedTooltip,
        },

        props: {
            title: {
                type: String,
                required: true,
            },
            widgetData: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
            riskScoreType: {
                type: String,
                required: true,
            },
            numRiskTypes: {
                type: Number,
                required: true,
            },
        },

        data () {
            return {
                defaultHeaders: {
                    firstName: this.$t('userListHeaders.firstName'),
                    lastName: this.$t('userListHeaders.lastName'),
                    job: this.$t('userListHeaders.job'),
                    gender: this.$t('userListHeaders.gender'),
                    risk: (this.numRiskTypes > 1 && this.riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY) ?
                        this.$t(`userListHeaders.averageRiskSeverity`) :
                        this.$t(`userListHeaders.${this.riskScoreType}`),
                },
                currentSort: 'risk',
                currentSortDirection: 'down',
            }
        },

        computed: {
            headers () {
                if (!this.loading) {
                    if (this.widgetData.hasOwnProperty('headers')) {
                        return this.widgetData.headers
                    }
                    return this.defaultHeaders
                }
                return ''
            },

            users () {
                let userList = []
                if (!this.loading) {
                    userList = cloneDeep(this.widgetData.data)
                    userList.sort((a,b) => {
                        let direction = 1
                        if (this.currentSortDirection === 'down') direction = -1
                        if (a[this.currentSort] < b[this.currentSort]) return -1 * direction
                        if (a[this.currentSort] > b[this.currentSort]) return direction
                        return 0
                    }).map((user) => {
                          user.risk = formatRiskScore(user.risk, this.riskScoreType) // This will basically take the first 3 digit after the decimal point and turn it into a string of a whole number
                          return user
                    })
                }
                return userList
            },
        },

        methods: {
            openIndividualSummary (workerId) {
                if (workerId !== undefined) {
                    this.$router.push({name: 'individual-summary', params: {workerId: workerId}})
                }
            },

            setCurrentSort (header) {
                if (header === this.currentSort) {
                    this.currentSortDirection = this.currentSortDirection === 'up' ? 'down' : 'up'
                }
                this.currentSort = header
            },

            setColor (header, risk) {
                if (header !== Object.keys(this.defaultHeaders)[4]) {
                  return ''
                }

                if (this.riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY) {
                    if (risk[0] >= 1) {
                        return constants.WIDGET_LIST_RISK_COLORS.RED
                    }
                    else if (risk[1] >= 1) {
                        return constants.WIDGET_LIST_RISK_COLORS.ORANGE
                    }
                    else if (risk[2] >= 1) {
                        return constants.WIDGET_LIST_RISK_COLORS.YELLOW
                    }
                    else {
                        return constants.WIDGET_LIST_RISK_COLORS.GREEN
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-WidgetUserList {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin-left: 0.15rem;

        &-table {
            display: grid;
            grid-template-columns:
                minmax(2.5rem, 3fr)
                minmax(2.5rem, 3fr)
                minmax(3.8rem, 4.5fr)
                minmax(2.5rem, 3fr)
                minmax(5rem, 2.5fr)
                repeat(auto-fit, minmax(2rem, 5.5fr));
            grid-template-rows: 2.5rem;
            border-collapse:collapse;
            font-size: 0.75rem;
            $border-height: width;
            height: 100%;
            table-layout: fixed;

            &-headers {
                grid-column: 1/-1;
                display: grid;
                grid-template-columns: inherit;
                height: 20rem;
                margin-right: 0.35rem;

                &-header {
                    @extend %font-table-heading;
                    color: $color-table-font-header;
                    user-select: none;
                    &-titleContainer {
                        &-firstName, &-lastName, &-job, &-gender {
                            padding: 10% 0;
                        }
                        &-title {
                            padding-right: 0.28rem;
                            padding-left: 0.8rem;
                        }
                    }

                    &-showColour {
                        background-color: $color-table-col-select;
                        border-radius: 2px;
                    }

                    &-gender, &-risk {
                        padding-top: 0.1rem;
                    }

                    &-content {
                        margin-top: 0.5125rem;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        overflow-wrap: break-word;
                    }

                    &-container {
                        margin-top: 0.3rem;
                        &-arrow {
                            padding: 0 0.05rem;
                            visibility: hidden;

                            &-show {
                                visibility: visible;
                            }

                            &-up {
                                transform: rotate(180deg)
                            }
                        }
                    }
                }
            }
            &-body {
                @include scrollbar-widget;
                overflow-y: scroll;
                grid-column: 1/-1;
                height: 100%;
                grid-template: inherit;
                border-radius: 0.125rem;
                margin-top: 0.325rem;

                &-rows {
                    grid-column: 1/-1;
                    display: grid;
                    grid-template-columns: inherit;
                    margin-right: 0.15rem;

                    &-row {
                        padding: 0.3rem 0;
                        grid-column: 1/-1;
                        display: grid;
                        grid-template-columns: inherit;
                        color: $color-table-font-white;

                        &-clickable {

                            &:hover {
                                border-radius: 2px;
                                background-color: $color-table-row-hover;
                                width: 100%;
                                cursor: pointer;
                            }
                        }

                        &-item {
                            height: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0.625rem 0;

                            &-latestRiskScore {
                                display: flex;
                                justify-content: flex-end;
                            }

                            &-firstName, &-job, &-lastName {
                                justify-content: center;
                                padding: 0.625rem 0.6rem;
                            }

                            &-data {
                                &-firstName, &-job, &-lastName {
                                    text-align: left;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                &-gender {
                                    align-content: center;
                                    justify-content: center;
                                }

                                &-risk {
                                    &-red {
                                        color: $color-risk-red;
                                    }
                                    &-orange {
                                        color: $color-risk-orange;
                                    }
                                    &-yellow {
                                        color: $color-risk-yellow;
                                    }
                                    &-green {
                                        color: $color-risk-green;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
