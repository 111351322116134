<template lang="pug">
    Layout(:showWorkerCard="showWorkerCard")
        SenzDashboard
</template>

<script>
    import { mapGetters } from 'vuex'
    import Layout from '../layouts/main'
    import SenzDashboard from 'components/Dashboards/SenzDashboard'

    export default {
        name: "Home",

        components: {
            Layout,
            SenzDashboard,
        },

        computed: {
            ...mapGetters({

            }),

            showWorkerCard () {
                return false
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>