<template lang="pug">
    div(
        class="sz-Widget",
        :class="'sz-Widget-' + currentWidgetType")
        div(class="sz-Widget-topBar" v-on:mouseover="isTopBarDragged")
            div(class="sz-Widget-title")
                div(class="sz-Widget-title-text") {{ widgetTitle }}
            FormSectionToggle(v-if="isRiskSeverityGraph",
                            :tooltip="$t('enhanceRedSeverityLabels.toggleGraphTooltip')",
                            :defaultChecked="false",
                            class="sz-Widget-toggle",
                            :showTitle="false",
                            :title="widgetSettings.id",
                            @changed="setGraphToggle")
            div(
                class="sz-Widget-refresh",
                @click.stop="refreshWidget")
                SvgIcon(
                    icon="refresh",
                    width="1.1rem",
                    height="1.1rem",
                    class="sz-Widget-refreshIcon")
            div(
                v-tooltip="parametersTooltip",
                :id="`sz-Widget-info-${parsedWidgetSettings.order}`",
                class="sz-Widget-info")
                SvgIcon(
                    icon="info",
                    width="0.729375rem",
                    height="0.729375rem",
                    class="sz-Widget-editIcon")
            div(
                @click.stop="openDropdown",
                class="sz-Widget-edit")
                SvgIcon(
                    icon="menu",
                    width="0.25rem",
                    height="1rem",
                    class="sz-Widget-editIcon")
                transition(name="slide")
                    DropdownEditWidget(
                        v-if="dropdownOpen",
                        @closeDropdown="closeDropdown",
                        :widgetSettings="parsedWidgetSettings",
                        :widgetData="widgetData",
                        :chart="currentChart")

        div(:class="`sz-Widget-data sz-Widget-data-${currentWidgetType}`" v-on:mouseover="isWidgetDragged")
            transition(name="fade")
            div(
                v-if="error",
                class="sz-Widget-error sz-Widget-loader") {{ $t(`widgets.errorMessage`) }}
            div(
                v-if="widgetLoading",
                class="sz-Widget-loader")
                LoadingSpinner
            component(
                v-if="!widgetLoading"
                :is="currentWidgetType",
                :title="widgetTitle",
                :distributionType="parsedWidgetSettings.type",
                :loading="widgetLoading",
                :riskScoreType="currentRiskScoreType",
                :widgetData="widgetData",
                :numRiskTypes="numSelectedRiskTypes",
                :toggleGraph="toggleGraph"
                ref="widgetChart")
</template>

<script>
    import constants from 'helpers/constants'
    import WidgetsService from 'services/widgets.service'
    import { cloneDeep } from 'lodash'
    import { mapGetters } from 'vuex'
    import { tooltip } from 'directives/tooltip'
    import { rdfDecoder } from 'helpers/rdfHelper'
    import moment from 'moment-timezone'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'

    import WidgetTrendChart from './WidgetTrendChart'
    import WidgetDistributionBarChart from './WidgetDistributionBarChart'
    import WidgetUserList from './WidgetUserList'
    import WidgetAssessmentSummary from './WidgetAssessmentSummary'
    import WidgetChangeAnalysisChart from './WidgetChangeAnalysisChart'
    import SvgIcon from '../Shared/SvgIcon'
    import DropdownEditWidget from './DropdownEditWidget'
    import { userHasAnyRole } from 'helpers/permissionsHelper'
    import FormSectionToggle from 'components/Shared/FormSectionToggle'

    export default {
        name: "Widget",

        components: {
            WidgetTrendChart,
            WidgetDistributionBarChart,
            WidgetUserList,
            WidgetAssessmentSummary,
            WidgetChangeAnalysisChart,
            SvgIcon,
            DropdownEditWidget,
            LoadingSpinner,
            FormSectionToggle,
        },

        directives: {
            tooltip,
        },

        props: {
            widgetSettings: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                widgetData: {},
                widgetLoading: true,
                dropdownOpen: false,
                errorStatus: false,
                toggleGraph: false,
            }
        },

        errorCaptured: function(err, component, details) {
            this.error = true
        },

        computed: {
            ...mapGetters([
                'userID',
                'currentCompany',
                'currentCustomer',
                'auth0User',
                'allAssessmentTags',
            ]),

            error: {
                set(bool){
                    this.errorStatus = bool
                },

                get() {
                    return this.errorStatus
                },
            },

            allAssessmentTagIDs () {
                return this.allAssessmentTags.map(tag => tag.id)
            },

            currentWidgetType () {
                let type = constants.WIDGET_TYPE_API_KEY_COMPONENT_MAP[this.parsedWidgetSettings.type]
                if (Object.values(constants.DISTRIBUTION_CHART_TYPE_API_KEYS).includes(this.parsedWidgetSettings.type)) {
                    type = constants.WIDGET_TYPES_COMPONENT_NAMES.DISTRIBUTION
                }

                if (Object.values(constants.ANALYSIS_CHART_TYPE_API_KEYS).includes(this.parsedWidgetSettings.type)) {
                    type = constants.WIDGET_TYPES_COMPONENT_NAMES.CHANGE_ANALYSIS
                }

                return type
            },

            currentRiskScoreType () {
                return this.parsedWidgetSettings.riskScoreType
            },

            numSelectedRiskTypes () {
                return this.parsedWidgetSettings.riskTypes.length
            },

            widgetTitle () {
                return this.parsedWidgetSettings.title
            },

            parsedWidgetSettings () {
                // look thru prop data, make sure the settings that belong in the prop data are correct.
                // fill in null values as needed to prevent errors. will test later  once data is coming.

                let parsedPropSettings = {
                    order: undefined,
                    id: undefined,
                    title: undefined,
                    type: undefined,
                    riskScoreType: undefined,
                    interval: undefined,
                    riskTypes: [],
                    genders: [],
                    jobTypes: [],
                    assessmentTags: [],
                }

                if (this.isAnalysisChart) {
                    parsedPropSettings = {
                        order: undefined,
                        id: undefined,
                        title: undefined,
                        type: undefined,
                        groups: [
                            {
                                label: undefined,
                                assessmentTags: [],
                                interval: undefined,
                            },
                            {
                                label: undefined,
                                assessmentTags: [],
                                interval: undefined,
                            },
                        ],
                        riskScoreType: undefined,
                        riskTypes: [],
                        genders: [],
                        jobTypes: [],
                    }
                }

                for (let setting of Object.keys(parsedPropSettings)) {
                    if (this.widgetSettings[setting]) {
                        parsedPropSettings[setting] = this.widgetSettings[setting]
                    } else if (this.widgetSettings.filters[setting]) {
                        parsedPropSettings[setting] = this.widgetSettings.filters[setting]
                    }
                    else {
                        parsedPropSettings[setting] = undefined
                    }
                }

                return parsedPropSettings
            },

            readableParametersList () {
                let interval = ""
                let riskType = ""
                let gender = ""
                let job = ""
                let assessmentTags = ""

                if (this.parsedWidgetSettings.interval) {
                    interval = Object.values(constants.INTERVAL_CODES).includes(this.parsedWidgetSettings.interval)
                        ? this.$t(`modals.modalEditWidget.presetTypes.${constants.INTERVAL_DATE_PRESET_MAP[this.parsedWidgetSettings.interval]}`)
                        : this.formatStringInterval(this.parsedWidgetSettings.interval)
                }

                if (this.parsedWidgetSettings.type !== constants.DISTRIBUTION_CHART_TYPE_API_KEYS.RISK_TYPE_DIST_CHART
                    && (this.parsedWidgetSettings.riskTypes && this.parsedWidgetSettings.riskTypes.length > 0)) {
                    riskType = this.parsedWidgetSettings.riskTypes
                        .map(risk => this.$t(`riskTypes.${risk}`))
                        .join(', ')
                }

                if (this.parsedWidgetSettings.type !== constants.DISTRIBUTION_CHART_TYPE_API_KEYS.TAG_DIST_CHART
                    && (this.parsedWidgetSettings.assessmentTags && this.parsedWidgetSettings.assessmentTags.length > 0)
                    && (this.allAssessmentTags && this.allAssessmentTags.length > 0)) {
                    assessmentTags = this.parsedWidgetSettings.assessmentTags
                        .filter(tagID => this.allAssessmentTagIDs.includes(tagID))
                            .map(curTagID => this.allAssessmentTags
                                .find(tag => tag.id === curTagID).name)
                            .join(', ')
                }

                if (this.parsedWidgetSettings.genders && this.parsedWidgetSettings.genders.length > 0) {
                    gender = this.parsedWidgetSettings.genders
                        .map(type => constants.GENDER_NAME_API_MAP[type])
                        .join(', ')
                }

                if (this.parsedWidgetSettings.type !== constants.DISTRIBUTION_CHART_TYPE_API_KEYS.JOB_TYPE_DIST_CHART
                    && (this.parsedWidgetSettings.jobTypes && this.parsedWidgetSettings.jobTypes.length > 0)) {
                    job = this.parsedWidgetSettings.jobTypes.join(', ')
                }

                return [interval, riskType, assessmentTags, gender, job]
            },

            parametersTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'left',
                            padding: 'extra-padding',
                            width: 'parameters-width',
                        },
                        title: this.$t('homePage.filters'),
                        list: this.readableParametersList,
                    },

                    body: {
                        placement: 'bottom-left',
                        offset: '-120, 15',
                    },
                    allowHover: true,
                }
            },

            currentChart () {
                if (this.widgetSettings.type !== constants.WIDGET_TYPES_API_KEYS.LIST
                    && this.widgetSettings.type !== constants.WIDGET_TYPES_API_KEYS.SUMMARY) {
                    return this.$refs.widgetChart.exportChart()
                }
                return undefined
            },

            isRiskSeverityGraph() {
                return (this.widgetSettings.type === constants.WIDGET_TYPES_API_KEYS.TREND ||
                    this.currentWidgetType === constants.WIDGET_TYPES_COMPONENT_NAMES.DISTRIBUTION ||
                    this.currentWidgetType === constants.WIDGET_TYPES_COMPONENT_NAMES.CHANGE_ANALYSIS) &&
                    this.widgetSettings.riskScoreType === constants.RISK_SCORE_TYPES_API_KEYS.RISK_SEVERITY
            },

            isAnalysisChart() {
                return this.widgetSettings.type === constants.ANALYSIS_CHART_TYPE_API_KEYS.TAG_ANALYSIS_CHART ||
                    this.widgetSettings.type === constants.ANALYSIS_CHART_TYPE_API_KEYS.TIME_TYPE_ANALYSIS_CHART
            },
        },

        watch: {
            parsedWidgetSettings () {
                if (userHasAnyRole(this.auth0User, [constants.ROLES.CUSTOMER_ASSESSOR, constants.ROLES.LB_ASSESSOR])) {
                    this.getUnrestrictedWidgetData()
                } else {
                    this.getWidgetData()
                }
            },
        },

        mounted () {
            if (userHasAnyRole(this.auth0User, [constants.ROLES.CUSTOMER_ASSESSOR, constants.ROLES.LB_ASSESSOR])) {
                this.getUnrestrictedWidgetData()
            } else {
                this.getWidgetData()
            }
        },

        methods: {
            async refreshWidget() {
              if (userHasAnyRole(this.auth0User, [constants.ROLES.CUSTOMER_ASSESSOR, constants.ROLES.LB_ASSESSOR])) {
                  this.getUnrestrictedWidgetData()
              } else {
                  this.getWidgetData()
              }
            },

            async getWidgetData () {
                this.errorStatus = false
                this.widgetLoading = true
                try {
                    let data = await WidgetsService.getWidgetData(
                        this.currentCustomer,
                        this.currentCompany,
                        this.userID,
                        this.parsedWidgetSettings.id)
                    let widgetOrder = this.widgetSettings.order
                    this.widgetData = {
                        data,
                    }
                    this.$set(this.widgetData, 'widgetOrder', this.widgetSettings.order)
                } catch {
                    this.widgetData =  {
                        data: [],
                    }
                }
                this.widgetLoading = false
            },

            async getUnrestrictedWidgetData () {
                this.errorStatus = false
                this.widgetLoading = true
                try {
                    let data = await WidgetsService.getUnrestrictedWidgetData(
                        this.currentCustomer,
                        this.currentCompany,
                        this.userID,
                        this.parsedWidgetSettings.id)
                    let widgetOrder = this.widgetSettings.order
                    this.widgetData = {
                        data,
                    }
                    this.$set(this.widgetData, 'widgetOrder', this.widgetSettings.order)
                } catch {
                    this.widgetData =  {
                        data: [],
                    }
                }
                this.widgetLoading = false
            },

            setCustomHeaders () {
                if (this.parsedWidgetSettings.type === constants.WIDGET_TYPES_API_KEYS.LIST) {
                    let customHeaders = cloneDeep(constants.WIDGET_LIST_HEADERS)
                    customHeaders.risk = this.parsedWidgetSettings.interval.includes(`$`)
                        ? this.$t(`widgets.averageRiskScore`)
                        : customHeaders.risk
                    this.$set(this.widgetData, 'headers', customHeaders)
                }
            },

            openDropdown () {
                this.dropdownOpen = true
            },

            closeDropdown () {
                this.dropdownOpen = false
            },

            setGraphToggle(newInput) {
                this.toggleGraph = newInput
            },

            formatStringInterval (interval) {
                let [start, end] = rdfDecoder(interval)
                start = moment(start * constants.UNIX_MILLISECONDS).format("MM/DD/YYYY")
                end = moment(end * constants.UNIX_MILLISECONDS).format("MM/DD/YYYY")
                return `${start} - ${end}`
            },

            isTopBarDragged() {
                this.$emit('isDraggableDisabled', false)
            },

            isWidgetDragged() {
                this.$emit('isDraggableDisabled', true)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-Widget {
        background-color: $color-box-background;
        color: $color-text;
        display: flex;
        flex-direction: column;
        border-radius: 0.25rem;
        max-height: 18.75rem;
        min-width: 0;
        width: 28rem;
        height: 18.75rem;
        max-width: 28.5rem;

        &-loader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-color: lighten($color-body-bg, 10%);
            opacity: 0.5;
            filter: blur(1px);
        }

        &-error {
            filter: blur(0px);
            white-space: pre-line;
        }

        &-topBar {
            height: 1.75rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: $color-box-header;
            padding: 1rem 1.25rem;
            border-radius: 0.25rem 0.25rem 0 0;
            cursor: default;
        }

        &-toggle {
            margin-top: 1px;
            padding-right: 0.5rem;
        }

        &-edit {
            padding-top: .35rem;
            position: relative;
            cursor: pointer;
        }

        &-info {
            padding-top: .35rem;
            margin: 0 1.25rem;
            position: relative;
            cursor: pointer;
        }

        &-refresh {
            padding-top: .35rem;
            position: relative;
            cursor: pointer;
            color: $color-button-widget;
        }

        &-title {
            @extend %font-heading;
            letter-spacing: 0.04rem;
            padding: 0.25rem 0.5rem;
            display: flex;
            text-align: left;
            cursor: default;
            min-width: 0;
            font-size:0.75rem;

            &-text {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 20.3rem;
                white-space: nowrap;
            }
        }

        &-data {
            min-height: 0;
            height: 100%;
            flex-grow: 1;
            margin: 0.25rem;
            padding-right: 0.25rem;
            &-WidgetUserList {
                @include scrollbar-widget;
                overflow-y: auto;
            }
        }
    }

    .slide-enter-active {
        transition: all .3s ease;
    }
    .slide-leave-active {
        transition: all .2s ease;
    }
    .slide-enter {
        transform: translateY(-10px);
        opacity: 0;
    }
    .slide-leave-to {
        transform: translateY(-10px);
        opacity: 0;
    }

</style>
